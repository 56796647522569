<template>
  <div class="acn-available-jobs">
    <vue-good-table
      mode="remote"
      @on-page-change="
        (val) => {
          $emit('onPageChange', val);
        }
      "
      @on-per-page-change="
        (val) => {
          $emit('onPerPageChange', val);
        }
      "
      @on-row-click="
        ({ row }) => {
          $emit('click', row.id);
        }
      "
      :columns="tableColumns"
      :rows="tableData"
      :totalRows="tablePagination.total"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: perPageOptions,
        dropdownAllowAll: false
      }"
      :sort-options="{
        enabled: false,
        initialSortBy: { field: 'updatedAt', type: 'desc' }
      }"
      :isLoading="isLoading"
    >
      <!-- Empty State -->
      <div slot="emptystate">
        <no-data message="No results for jobs."></no-data>
      </div>
      <!-- Loading State -->
      <div slot="loadingContent">
        <div class="d-flex justify-content-center">
          <spinner></spinner>
        </div>
      </div>
      <!-- Table Buttons -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'property'">
          <PropertySummary
            :property="props.row.acnQuest.listing.property"
            address
            :purpose="false"
          ></PropertySummary>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {
    tableColumns: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    tablePagination: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    onPageChange: {
      type: Function
    },
    onPerPageChange: {
      type: Function
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
